




























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import camelcaseKeys from 'camelcase-keys';

// import { toasts } from '@/utils/toasts';
// import i18n from '@/i18n';

import { toasts } from '@/utils/toasts';
import {
  getAssociatedUsers, getInventoryDetails, updateInventoryDetails, deleteInventory,
} from './inventory-management-api';

@Component
export default class extends Vue {
  hoveredRowIndex = -1;

  details: any = {};
  associatedUsers: Array<any> = [];

  loading = false;
  inventoryId = -1;

  async onDelete() {
    if (!(await this.$bvModal.msgBoxConfirm('Confirmi ștergerea acestei gestiuni?'))) {
      return;
    }

    this.loading = true;
    try {
      await deleteInventory(this.inventoryId);
      toasts.success('Gestiunea a fost ștearsă cu succes.');
      this.$router.push({ name: 'ApplicationSettings.InventoryManagement.Index' });
    } catch (err) {
      toasts.error(<string>((<any>err)?.response?.data?.errorMessage));
    } finally {
      this.loading = false;
    }
  }

  async onSaveDetails() {
    this.loading = true;

    try {
      await updateInventoryDetails(this.inventoryId, {
        label: this.details.label,
        description: this.details.description,
        storekeeper: this.details.storekeeper,
        receivingCommitteeMembers: this.details.receivingCommitteeMembers,
        isActive: this.details.isActive,
      });

      this.details = camelcaseKeys(await getInventoryDetails(this.inventoryId));
      this.associatedUsers = camelcaseKeys(await getAssociatedUsers(this.inventoryId));
    } finally {
      this.loading = false;
    }
  }

  async loadGridPage() {
    this.loading = true;
    this.inventoryId = Number.parseInt(this.$route.params.inventoryId, 10);

    try {
      this.details = camelcaseKeys(await getInventoryDetails(this.inventoryId));
      this.associatedUsers = camelcaseKeys(await getAssociatedUsers(this.inventoryId));
    } finally {
      this.loading = false;
    }
  }

  async initialize() {
    await this.loadGridPage();
  }

  created() {
    this.initialize();
  }
}
